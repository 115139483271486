import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import EventBus from './plugins/EventBus';
import './plugins/vuetify-mask';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import 'sweetalert2/src/sweetalert2.scss';

import es from 'vuetify/es5/locale/es';

Vue.config.productionTip = false;
Vue.prototype.$bus = EventBus;
Vue.use(VueSweetalert2);

vuetify.framework.lang.current = 'es';
vuetify.framework.lang.locales = { es };

new Vue({
  router,
  vuetify,
  render(h) {
    return h(App);
  },
}).$mount('#app');
