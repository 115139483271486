/* eslint-disable */
export const API_URL = process.env.VUE_APP_API_URL;
export const SIMULADOR_URL = process.env.VUE_APP_SIMULADOR_URL;
export const TRILAY_API_URL =
  process.env.VUE_APP_TRILAY_API || 'http://localhost:3000/';
export const trilayApiNuevoLegajoEndpoint = 'api/trilay/alta_legajo_credito';
export const datosAltaLegajos = 'api/liquidacion/datosAltaLegajos';
export const TIPO_FACTURA = [
  { value: 1, text: '1 - Facturas A' },
  { value: 2, text: '2 - Notas de Debito A' },
  { value: 3, text: '3 - Notas de Credito A' },
  { value: 4, text: '4 - Recibos A' },
  { value: 5, text: '5 - Notas de Venta al contado A' },
  { value: 6, text: '6 - Facturas B' },
  { value: 7, text: '7 - Notas de Debito B' },
  { value: 8, text: '8 - Notas de Credito B' },
  { value: 9, text: '9 - Recibos B' },
  { value: 10, text: '10 - Notas de Venta al contado B' },
  { value: 11, text: '11 - FACTURAS C' },
  { value: 12, text: '12 - NOTAS DE DEBITO C' },
  { value: 13, text: '13 - NOTAS DE CREDITO C' },
  { value: 15, text: '15 - RECIBOS C' },
  { value: 19, text: '19 - Facturas de Exportacion' },
  { value: 20, text: '20 - N. Deb. p/operac. con el exterior' },
  { value: 21, text: '21 - N. Cre. p/operac. con el exterior' },
  { value: 22, text: '22 - Fac. Perm. Export. Simp. - Dto.855/97' },
  { value: 30, text: '30 - Cbtes. compra de bienes usados' },
  { value: 31, text: '31 - Mandato/Consignación' },
  {
    value: 32,
    text: '32 - COMPROBANTES DE COMPRA DE MATERIALES A RECICLAR PROVENIENTES',
  },
  {
    value: 34,
    text: '34 - Cbtes. A del Anexo I, Apartado A,inc.f),R.G.Nro. 1415',
  },
  {
    value: 35,
    text: '35 - Cbtes. B del Anexo I,Apartado A,inc. f),R.G. Nro. 1415',
  },
  { value: 37, text: '37 - N. Deb/doc. equiv. que cumplan con R.G.Nro. 1415' },
  { value: 38, text: '38 - N. Cred/doc. equiv. que cumplan con R.G.Nro. 1415' },
  {
    value: 39,
    text: '39 - Otros comprobantes A que cumplan con R.G.Nro. 1415',
  },
  {
    value: 40,
    text: '40 - Otros comprobantes B que cumplan con R.G.Nro. 1415',
  },
  {
    value: 41,
    text: '41 - OTROS COMPROBANTES C QUE CUMPLAN CON LA R.G. N° 1415',
  },
  { value: 51, text: '51 - Facturas M' },
  { value: 52, text: '52 - Notas de Debito M' },
  { value: 53, text: '53 - Notas de credito M' },
  { value: 54, text: '54 - Recibo M' },
  { value: 55, text: '55 - Notas de Venta al contado M' },
  {
    value: 56,
    text: '56 - Comprobantes M del anexo I, Apartado A,inc. f)R.G.Nro.1415',
  },
  {
    value: 57,
    text: '57 - Otros Comprobantes M que cumplan con la R.G. Nro. 1415',
  },
  { value: 58, text: '58 - Cuenta de Venta y Liquido producto M' },
  { value: 59, text: '59 - Liquidacion M' },
  { value: 60, text: '60 - Cta de Vta y Liquido prod. A' },
  { value: 61, text: '61 - Cta de Vta y Liquido prod. B' },
  { value: 63, text: '63 - Liquidacion A' },
  { value: 64, text: '64 - Liquidacion B' },
  { value: 70, text: '70 - Recibo de Factura de Credito R' },
  { value: 91, text: '91 - Remito R' },
  { value: 49, text: '49 - Comprobante de Compra de Bienes Usados' },
  { value: 81, text: '81 - Tique Factura A' },
  { value: 82, text: '82 - Tique Factura B' },
  { value: 83, text: '83 - Tique' },
  { value: 111, text: '111 - Tique Factura C' },
  {
    value: 180,
    text: '180 - Cuenta de venta y líquido producto A - Sector pecuario',
  },
  {
    value: 182,
    text: '182 - Cuenta de venta y líquido producto B - Sector pecuario',
  },
  { value: 183, text: '183 - Liquidación de compra A - Sector pecuario' },
  { value: 185, text: '185 - Liquidación de compra B - Sector pecuario' },
  {
    value: 186,
    text: '186 - Liquidación de compra directa A - Sector pecuario',
  },
  {
    value: 188,
    text: '188 - Liquidación de compra directa B - Sector pecuario',
  },
  {
    value: 189,
    text: '189 - Liquidación de compra directa C - Sector pecuario',
  },
  {
    value: 190,
    text: '190 - Liquidación de venta directa A - Sector pecuario',
  },
  {
    value: 191,
    text: '191 - Liquidación de venta directa B - Sector pecuario',
  },
];
export default API_URL;
