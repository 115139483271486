import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/listado/:model/',
    name: 'Listado',
    component: () => import('../views/Listado.vue'),
  },
  {
    path: '/nuevo/:model/',
    name: 'Nuevo',
    component: () => import('../views/Nuevo.vue'),
  },
  {
    path: '/editar/:model/:id',
    name: 'Editar',
    component: () => import('../views/Nuevo.vue'),
  },
  {
    path: '/gestionar/:id',
    name: 'Gestionar',
    component: () => import('../views/CreditoView.vue'),
  },
  {
    path: '/reportes',
    name: 'GenerarReportes',
    component: () => import('../views/GenerarReportes.vue'),
  },
  {
    path: '/tasavariable/',
    name: 'TasaVariable',
    component: () => import('../views/TasaVariable.vue'),
  },
  {
    path: '/cuotas/',
    name: 'Cuotas',
    component: () => import('../views/Cuotas.vue'),
  },
  {
    path: '/legajos/importarcsv/',
    name: 'ImportarLegajoCsv',
    component: () => import('../views/ImportarCsv.vue'),
  },
  {
    path: '/legajos/importar/',
    name: 'ImportarLegajo',
    component: () => import('../views/ImportarLegajo.vue'),
  },
  {
    path: '/legajos/ImportarCuadroMarcha/',
    name: 'ImportarCuadroMarcha',
    component: () => import('../views/ImportarCuadroMarcha.vue'),
  },
  {
    path: '/legajos/DatosHistoricos/',
    name: 'ImportarDatosHistoricos',
    component: () => import('../views/ImportarDatosHistoricos.vue'),
  },
  {
    path: '*',
    component: () => import('../views/404.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
  },
  {
    path: '/pass',
    name: 'Pass',
    component: () => import('../views/Pass.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const tokenLS = localStorage.getItem('token');
  if (
    to.name !== 'Login' &&
    to.name !== 'Register' &&
    to.name !== 'afterVerifyError' &&
    to.name !== 'afterVerifyOk' &&
    !tokenLS
  )
    next({ name: 'Login' });
  else next();
});

export default router;
